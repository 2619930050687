import React, { useEffect, useState, useRef, useCallback, memo } from 'react';
// import { useSocket } from '../../hooks/socket';
import { useTranslation } from 'react-i18next';

import {
  restringirNome,
  formatPhoneNumber,
  formatTimeMensagens,
  formatDataHistorico,
  parseVcard
} from '../../utils/formatDados';

import { Container, RecuperarMensagensAntigas, Resposta } from './styles';
import Tooltip from '../Tooltip';

import reactStringReplace from 'react-string-replace';

import { VscReply, VscSync } from 'react-icons/vsc';

import { RespostasRapidas } from '../../components/respostasRapidas/index';

import { useResposta } from '../../hooks/responderMsgContext';
// import { useImageExpand } from '../../hooks/imageExpand';
// import {
//   useContatos,
//   useLojaAtiva,
//   useContatoAtivo,
//   useLastMsg,
// } from '../../hooks/dadosSak';

import Avatar from '../../components/avatar';

import { ExpandImage } from '../expandImage';

// import { gamification } from '../../utils/gamification';
import DropdownMensagem from './components/DropdownMensagem';
// import ModalDeleteMessage from './components/ModalDeleteMessage';
import useDadosSakStore from '../../stores/useDadosSakStore';
import useImageExpand from '../../stores/useImageExpand';
import useSocketStore from '../../stores/useSocketStore';
import { classnames } from '../../utils/usefulFunctions';

const infoTypesWhatsapp = [
  'e2e_notification',
  'call_log',
  'notificati',
  'ciphertext',
  'notification_template',
];

function Conversa({ mensagens, userchat, inicial, lojas }) {
  // const { sendSocketDados } = useSocket();

  const sendSocketDados = useSocketStore((state) => state.sendSocketDados);
  // const { lastMsg } = useLastMsg();
  // const { contatos } = useContatos();
  // const { lojaAtivo } = useLojaAtiva();
  // const { contatoAtivo, setContatoAtivo } = useContatoAtivo();
  const [search, setSearch] = useState('');
  const { idMessage, setIdMessage } = useResposta();
  const respostaDadosRef = useRef(null);
  const msgsClass = document.querySelectorAll('.message');
  const conv = document.querySelectorAll('#msgConv');
  // const { setImageExpand } = useImageExpand();
  const { t } = useTranslation();

  const contatos = useDadosSakStore((state) => state.contatos);
  const contatoAtivo = useDadosSakStore((state) => state.contatoAtivo);
  const setContatoAtivo = useDadosSakStore(
    (state) => state.setContatoAtivoStore
  );
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const lastMsg = useDadosSakStore((state) => state.lastMsg);

  const setImageExpand = useImageExpand((state) => state.setImageExpandStore);

  const formatUserResponderMensagem = useCallback(
    (mensagem) => {
      let nome = '';
      const lojaWhatsapp = lojas.whatsapp.toString();

      if (!mensagem) return null;

      if (mensagem.me === '0') {
        nome =
          mensagem.nome && mensagem.nome.length
            ? mensagem.nome
            : formatPhoneNumber(mensagem.conversa.split('@')[0].toString());
      } else {
        nome =
          mensagem.userchat !== '-1'
            ? mensagem.userchat !== '0'
              ? userchat[mensagem.userchat]
                ? userchat[mensagem.userchat].nome
                : formatPhoneNumber(lojaWhatsapp)
              : formatPhoneNumber(lojaWhatsapp)
            : 'Automação';
      }

      const TextoMensagem = mensagem.anexo ? 'Anexo' : mensagem.texto;

      if (respostaDadosRef.current) {
        respostaDadosRef.current.children[0].innerText = nome;
        respostaDadosRef.current.children[1].innerHTML = TextoMensagem;

        const inputState = document.querySelector('#chat-id-1-input');
        inputState && inputState.focus();
      }
    },
    [lojas, userchat]
  );

  useEffect(() => {
    if (idMessage && idMessage.length) {
      const findResposta = idMessage.find(
        (item) => item.conversaID === contatoAtivo.chatid
      );
      if (findResposta) {
        const mensagem = mensagens[findResposta.idUnic];
        formatUserResponderMensagem(mensagem);
      }
    }
  }, [idMessage, contatoAtivo, formatUserResponderMensagem, mensagens]);

  useEffect(() => {
    const msgs = document.querySelectorAll('#msgConv');
    if (!msgs || (msgs && !msgs.length)) return;
    let findMessage = lastMsg?.[contatoAtivo?.chatid];

    const ultimaMsgId = msgs.length - 1;
    if (!findMessage || findMessage?.['status'] > 1) return;

    let { scrollTop, scrollHeight, offsetHeight } =
      document.querySelector('#container-chat') || {};

    scrollTop = scrollTop || 0;
    scrollHeight = scrollHeight || 0;
    offsetHeight = offsetHeight || 0;
    if (findMessage['me']) findMessage['me'] = Number(findMessage['me']);

    // Previnir que o scroll desça 100% quando a pessoa estiver lendo uma conversa
    if (
      findMessage?.['me'] !== 1 &&
      scrollHeight - 100 > scrollTop + offsetHeight
    )
      return;

    msgs?.[ultimaMsgId] &&
      msgs[ultimaMsgId].scrollIntoView({
        block: 'start',
        behavior: 'smooth',
        inline: 'start',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMsg]);

  useEffect(() => {
    const msgs = document.querySelectorAll('#msgConv');
    if (!msgs || (msgs && !msgs.length)) return;

    const ultimaMsgId = msgs.length - 1;
    msgs?.[ultimaMsgId] &&
      msgs[ultimaMsgId].scrollIntoView({
        block: 'start',
        behavior: 'instant',
        inline: 'start',
      });
    const naoLidas = contatoAtivo ? Number(contatoAtivo['naolidas']) : 0;

    if (naoLidas) {
      msgs?.[ultimaMsgId - naoLidas] &&
        msgs[ultimaMsgId - naoLidas].scrollIntoView({
          block: 'start',
          behavior: 'auto',
        });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contatoAtivo.chatid]);

  if (search) {
    const searchColappse = document.querySelector('#chat-1-search');
    msgsClass.forEach((item, index) => {
      item.classList.add('message-selected');
      item.addEventListener('click', function () {
        searchColappse.classList.remove('show');
        item.classList.add('message-active');
        setTimeout(() => {
          item.classList.remove('message-active');
        }, 2000);
        conv[index] &&
          conv[index].scrollIntoView({ block: 'center', behavior: 'smooth' });
        setSearch('');
      });
    });
    mensagens = Object.values(mensagens).filter((msg) => {
      return msg.texto.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
  } else {
    Array.from(msgsClass).some(
      (item) =>
        item.classList.contains('message-selected') &&
        item.classList.remove('message-selected')
    );
  }

  // Função de Puxar todas mensagens do whatsapp
  function carregarMensagens() {
    if (contatoAtivo && contatoAtivo['carregandoConversa']) {
      window.notifChat('Por favor, aguarde um momento...', 'error');
      return;
    }

    if (
      !sendSocketDados({
        function: 'carregarTodasMensagens',
        data: [
          {
            id: contatoAtivo.chatid,
            userserv: contatoAtivo.userserv,
          },
        ],
      })
    );

    setContatoAtivo(() => ({
      ...contatoAtivo,
      ...{
        carregandoConversa: true,
      },
    }));

    // gamification('carregarTodasMensagens');
  }

  function marcarLido() {
    if (
      !sendSocketDados({
        function: 'enviarMarcarLido',
        data: [
          {
            chatid: contatoAtivo.chatid,
            userserv: contatoAtivo.userserv,
            userchat: inicial.userchat,
          },
        ],
      })
    );
    setContatoAtivo(() => ({
      ...contatoAtivo,
      ...{
        naolidas: '0',
      },
    }));
  }

  function reenviarMensagem(mensagem) {
    const { idFull, userserv, userchat } = mensagem || {};

    if (
      !sendSocketDados({
        function: 'reenviarMensagem',
        data: [{ idFull, userserv, userchat }],
      })
    );

    window.notifChat('Reenviando mensagem...');
  }

  function responderUser(id) {
    const mensagem = mensagens[id];

    const findIdMessage = idMessage.findIndex(
      (item) => item.conversaID === mensagem.conversa
    );

    const { idFull, id: idUnic, conversa } = mensagem || {};

    if (!idFull || !idUnic) return null;

    if (findIdMessage < 0) {
      setIdMessage([
        ...idMessage,
        {
          id: idFull,
          idUnic: idUnic,
          conversaID: conversa,
        },
      ]);
    } else {
      let newArray = [...idMessage];
      newArray[findIdMessage] = {
        ...newArray[findIdMessage],
        id: idFull,
        idUnic: idUnic,
      };
      setIdMessage(newArray);
    }
  }

  function limparResponderUser() {
    if (idMessage.length) {
      const findResposta = idMessage.findIndex(
        (item) => item.conversaID === contatoAtivo.chatid
      );
      if (findResposta !== -1) {
        idMessage.splice(findResposta, 1);
        setIdMessage([...idMessage]);
      }
    }
  }

  function irParaMensagem(mensagem) {
    if (conv) {
      conv.forEach((item, index) => {
        if (item.dataset.id === mensagem.id) {
          conv[index] &&
            conv[index].scrollIntoView({ block: 'center', behavior: 'smooth' });
          item.classList.add('message-active');
          setTimeout(() => item.classList.remove('message-active'), 2000);
        }
      });
    }
  }

  function respostaAnexo(mensagem, resposta) {
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.webp)$/i;
    let allowedExtensionsAudio = /(\.mp3|\.ogg)$/i;
    let allowedExtensionsVideo = /(\.mp4)$/i;
    if (allowedExtensions.exec(mensagem.anexo)) {
      const returnImage = mensagem.anexo;
      return (
        <>
          <img
            src={returnImage}
            alt="anexo"
            loading="lazy"
            width={'100%'}
            style={{ objectFit: 'cover' }}
          />
        </>
      );
    } else if (allowedExtensionsAudio.exec(mensagem.anexo)) {
      return (
        <div
          className={`message-resposta-anexo-icon ${
            resposta.me === '1' ? 'verde' : ''
          }`}
        >
          <i className="fe fe-mic"></i>
        </div>
      );
    } else if (allowedExtensionsVideo.exec(mensagem.anexo)) {
      return (
        <div
          className={`message-resposta-anexo-icon ${
            resposta.me === '1' ? 'verde' : ''
          }`}
        >
          <i className="fe fe-video"></i>
        </div>
      );
    } else {
      return (
        <div
          className={`message-resposta-anexo-icon ${
            resposta.me === '1' ? 'verde' : ''
          }`}
        >
          <i className="fe fe-file"></i>
        </div>
      );
    }
  }

  function respostaMensagem(mensagem) {
    const groupRefer = mensagem.Nfrom && mensagem.Nfrom.split('@')[0];

    if (mensagem.respostaDe && mensagem.conversa && mensagem.userserv) {
      let msgResposta = mensagens[mensagem.respostaDe];
      if (typeof msgResposta === 'object') {
        const userPhone =
          msgResposta &&
          msgResposta.Nfrom &&
          formatPhoneNumber(msgResposta.Nfrom.split('@')[1]);
        const userName =
          msgResposta && msgResposta.Nfrom && msgResposta.Nfrom.split('~')[1];

        return (
          <>
            {mensagem.respostaDe && (
              <div
                onClick={() => irParaMensagem(msgResposta)}
                className="
                  message-content-resposta tw-flex tw-text-gray-900 dark:tw-text-white tw-bg-gray-400/10
                  [&_a]:tw-text-indigo-600 [&_a]:dark:tw-text-indigo-400
                  !tw-border-l-active
                "
              >
                <div
                  className="message-resposta-texto p-4"
                  style={{
                    wordBreak: 'break-word',
                    whiteSpace: 'break-spaces',
                  }}
                >
                  <strong
                    className="tw-text-active dark:tw-text-active-dark tw-text-[.8rem] tw-font-bold"
                    // style={{ color: '#6bcca4', fontSize: '0.8rem' }}
                  >
                    {mensagem.me === '0'
                      ? contatoAtivo.nome
                        ? contatoAtivo.nome
                        : groupRefer === 'g.us'
                        ? `${userPhone} ~ ${
                            userName !== 'undefined' ? userName : ''
                          }`
                        : formatPhoneNumber(
                            contatoAtivo.chatid &&
                              contatoAtivo.chatid.split('@') &&
                              contatoAtivo.chatid.split('@')[0]
                          )
                      : 'Você'}
                  </strong>
                  {msgResposta.texto ? (
                    restringirNome(msgResposta.texto, 60, true)
                  ) : (
                    <div className="d-flex align-items-center">
                      <i className="fe fe-link mr-2"></i>
                      <strong>Anexo</strong>
                    </div>
                  )}
                </div>
                {msgResposta.anexo && (
                  <div className="message-resposta-anexo">
                    {respostaAnexo(msgResposta, mensagem)}
                  </div>
                )}
              </div>
            )}
          </>
        );
      }
    }
  }

  function filterMensagem(mensagem) {
    let msgFormat = '';

    if (mensagem?.tipo === 'revoked') {
      msgFormat = t('Mensagem apagada');
    } else if (mensagem?.parsed) {
      return;
    } else {
      const regexpLinkFilter =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gim;
      const regexpBoldFilter = /(\*[^\s](?:[^*]*[^\s])?\*)/gi;
      const regexpItalicFilter = /(_[^\s](?:[^_]*[^\s])?_)/gi;
      const regexpstrikethroughFilter = /(~[^\s](?:[^~]*[^\s])?~)/gi;
      const regexpLongQuoteFilter = /(```[^\s](?:[^*]*[^\s])?```)/gi;
      // const regexpShortCodeFilter = /(`[^\s](?:[^*]*[^\s])?`)/gi;
      const regexpEncerrarAtendimento = /\*#Robô:\* Atendimento encerrado./gi;
      const regexQuebraLinhaBack = /(\\n)/gi;
      const regexLinkLily = /https:\/\/aval\.ie\/\w{8,}/i;

      if (mensagem.texto.match(regexpEncerrarAtendimento))
        mensagem.texto = '*#Robô:* Atendimento encerrado';

      if (mensagem.texto.includes('https://aval.ie/') && !regexLinkLily.test(mensagem.texto))
        mensagem.texto = 'Atendimento finalizado!';

      mensagem.texto = mensagem.texto.replace(/\\r/g, '');

      const checkForLinkSpecialCharacter = () => {
        if (
          mensagem.texto[0] === '*' &&
          mensagem.texto.slice(-1) === '*' &&
          mensagem.texto.includes('http')
        ) {
          return mensagem.texto.substring(1);
        } else {
          return mensagem.texto;
        }
      };
      // const validLink = (validateURL) => {
      //   const arr = validateURL.split('?');
      //   if (arr[0].slice(-1) === '*') {
      //     arr[0] = arr[0].replace('*', '');
      //   }
      //   return arr.join('');
      // };

      msgFormat = reactStringReplace(
        checkForLinkSpecialCharacter(),
        regexpLinkFilter,
        (match, i) => {
          const validateURL = match.includes('http')
            ? match
            : `http://${match}`;

          return (
            <a
              key={`inclui${i}`}
              href={validateURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {mensagem.texto.slice(-1) + mensagem.texto[0] === '**' ? (
                <strong key={match + i}>{match.replace('*', '')}</strong>
              ) : (
                match
              )}
            </a>
          );
        }
      );

      msgFormat = reactStringReplace(
        msgFormat,
        regexpBoldFilter,
        (match, i) => (
          <strong key={match + i}>{match.replace(/[*]/g, '')}</strong>
        )
      );

      msgFormat = reactStringReplace(
        msgFormat,
        regexpItalicFilter,
        (match, i) => (
          <em key={match + i}>{match.replace(/[_]/g, '')}</em>
        )
      );

      msgFormat = reactStringReplace(
        msgFormat,
        regexpstrikethroughFilter,
        (match, i) => (
          <del key={match + i}>{match.replace(/[~]/g, '')}</del>
        )
      );

      msgFormat = reactStringReplace(
        msgFormat,
        regexpLongQuoteFilter,
        (match, i) => (
          <blockquote key={match + i}>
            <span className="quote_bar"></span>
            <p className="quote_text">{match.replace(/```/g, '')}</p>
          </blockquote>
        )
      );

      // msgFormat = reactStringReplace(
      //   msgFormat,
      //   regexpShortCodeFilter,
      //   (match, i) => (
      //     <code key={match + i}>{match.replace(/`/g, '')}</code>
      //   )
      // );

      msgFormat = reactStringReplace(msgFormat, regexQuebraLinhaBack, () => (
        <br />
      ));
    }

    return (
      <div
        key={`${mensagem.inclusao}-${mensagem.id}`}
        className="d-block message--text"
        style={{ flex: 1, wordBreak: 'break-word', whiteSpace: 'break-spaces' }}
      >
        {msgFormat}
      </div>
    );
  }

  function autorMensagem(mensagem) {
    let nome = '';
    const lojaWhatsapp = lojas.whatsapp.toString();

    if (mensagem.me === '0') {
      nome =
        mensagem.nome && mensagem.nome.length
          ? mensagem.nome
          : formatPhoneNumber(mensagem.id.split('@')[0].toString());
    } else {
      nome =
        mensagem.userchat !== '-1'
          ? mensagem.userchat !== '0'
            ? userchat[mensagem.userchat]
              ? userchat[mensagem.userchat].nome
              : formatPhoneNumber(lojaWhatsapp)
            : formatPhoneNumber(lojaWhatsapp)
          : 'Automação';
    }
    return nome;
  }

  const calcStatusMessage = (mensagem) => {
    let statusAtual = '3';

    const contatoNaoLidas = contatoAtivo['naolidas']
      ? String(contatoAtivo['naolidas'])
      : 0;

    const naoLida = Object.values(mensagens)
      .sort((a, b) => (a.data_whats > b.data_whats ? 1 : -1))
      .slice(
        Number.isInteger(Number(contatoNaoLidas)) ? -Number(contatoNaoLidas) : 0
      )
      .map((item) => item.texto);

    if (naoLida.includes(mensagem.texto)) statusAtual = '2';
    if (contatoNaoLidas === '0' || contatoNaoLidas === '-1') statusAtual = '3';

    const status = {
      // 0: t('Enviado mas não recebido'),
      // '-1': t('Lida'),
      '-2': t('Enviando...'),
      '-1': t('Erro'),
      0: t('Enviando...'),
      1: t('Enviado mas não recebido'),
      2: t('Enviado e recebido'),
      3: t('Lida'),
      4: t('Reproduzido'),
    };

    return (
      <Tooltip
        text={
          mensagem.me === '1'
            ? status[String(mensagem.status)]
            : statusAtual === '2'
            ? t('Clique para marcar como visualizada para todos.')
            : status[String(statusAtual)]
        }
        position="right"
      >
        <div
          className={statusAtual === '2' ? 'pointer' : ''}
          data-for={mensagem.id}
          onClick={() => statusAtual === '2' && marcarLido()}
        >
          <img
            className="ml-2 mt-1"
            src={
              process.env.PUBLIC_URL +
              'assets/msgStatus' +
              (mensagem.me === '1' ? mensagem.status : statusAtual) +
              '.svg'
            }
            loading="lazy"
            alt={`status${mensagem.status}`}
          />
        </div>
      </Tooltip>
    );
  };

  /* function groupName(mensagem) {
    return (
      <div>
        <strong>{mensagem.Nfrom}</strong>~Pablo
      </div>
    );
  } */

  function historicoDeMensagens(mensagem, index, array) {
    return (
      index ===
        array.findIndex(
          (t) =>
            formatDataHistorico(t.data_whats) ===
            formatDataHistorico(mensagem.data_whats)
        ) && (
        <div
          className="message mt-3 mb-3"
          // id="historyConv"
        >
          <div className="message-body">
            <div className="message-row">
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="
                    message-content tw-rounded-md tw-text-center message-date
                    tw-bg-west-bar dark:tw-bg-west-bar-dark
                    tw-text-gray-500 dark:tw-text-gray-400
                  "
                >
                  {formatDataHistorico(mensagem.data_whats)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  function validateVcard(mensagem) {
    if (mensagem?.tipo !== 'vcard') return;

    const parsed = parseVcard(mensagem.texto);
    if (!parsed?.whatsappId) return;
    
    mensagem.parsed = true;

    // Da para melhorar de pegar as informações do business tbm

    const clickHandler = () => {
      const chatid = parsed.whatsappId + '@c.us'
      const novoContato = contatos[lojaAtivo][chatid] ?? { chatid, userchat, userserv: lojaAtivo };
      setContatoAtivo(() => novoContato);
    };

    return (
      <div style={{ width: '100%', maxWidth: '240px', minWidth: '180px' }}>
        <div className="card card-active-listener bg-transparent rounded-0 border-0">
          <div className="card-body pt-0 pb-0 mt-3 mb-3 pl-4 pr-5">
            <div
              className="d-flex align-items-center"
            >
              <span>
                <Avatar data={{ conversa: parsed.whatsappId }} />
              </span>
              <div className="media-body overflow-hidden">
                <div className="d-flex align-items-center justify-content-center mb-1">
                  <div
                    className="d-flex flex-column mr-auto"
                    style={{ maxWidth: '200px', wordBreak: 'break-word', fontSize: '0.85rem' }}
                  >
                    <h6 className="text-truncate mr-3 mb-0">
                    {parsed.fullName}
                    </h6>

                    <span
                      className="d-flex align-items-center"
                    >
                      {parsed.phoneNumber || parsed.whatsappId}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="btn btn-link"
            onClick={clickHandler}
          >
            {t("Enviar mensagem")}
          </button>
        </div>
      </div>
    );
  }

  function validateOrder(mensagem) {
    if (mensagem?.tipo !== 'order') return;

    let order;
    try {
      order = JSON.parse(mensagem.texto);
      // console.log(order);
    } catch {}

    if (!order || !order.catalog_url) return;
    mensagem.parsed = true;

    // Apenas caso haja mais de uma moeda
    const subtotal = {};
    if (order.items) {
      for (const item of order.items) {
        if (!subtotal[item.currency]) subtotal[item.currency] = 0;
        subtotal[item.currency] += item.price;
      }
    }

    return (
      <div style={{ width: '100%', maxWidth: '400px', minWidth: '200px' }}>
        {
          order.items?.map(item => (
            <div className="card card-active-listener bg-transparent rounded-0 border-0" key={ 'o' + mensagem.id + '_i' + item.sku }>
              <div className="card-body p-0">
                <div
                  className="d-flex align-items-center"
                >
                  <span>
                    <div className="mr-5 position-relative">
                      <img
                        src={item.image}
                        style={{ width: '42px', height: '42px', objectFit: 'cover' }}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </span>
                  <div className="media-body overflow-hidden">
                    <div className="d-flex align-items-center justify-content-center mb-1 flex-column">
                      <div
                        className="d-flex flex-column mr-auto"
                        style={{ maxWidth: '200px', wordBreak: 'break-word', fontSize: '0.85rem', lineHeight: '1.25' }}
                      >
                        <span className="text-truncate mr-3 mb-0 font-bold" title={item.name}>
                          {item.name}
                        </span>

                        <span
                          className="d-flex align-items-start flex-column"
                        >
                          <span>
                            {t("Qnt")}: {item.quantity} | {t("Preço")}: {item.price} {item.currency}
                          </span>
                          <span style={{ fontSize: '.65rem' }}>
                            {t("SKU")}: {item.sku}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }

        {
          order.text?.length ? (
            <div className="mt-3 pb-2">
              {order.text}
            </div>
          ) : undefined
        }

        <div className='mt-3'>
          {t("Itens")}: { order.items?.length }
        </div>
        <div className="d-flex" style={{ fontSize: '0.85rem', lineHeight: '1.25' }}>
          <span>
            {t("Subtotal")}:&nbsp;
          </span>
          <span className="d-flex flex-column align-items-start">
            {
              Object.entries(subtotal).map(([currency, subtotal]) => (
                <span key={ 'o' + mensagem.id + '_c' + currency }>
                  {subtotal} {currency}
                </span>
              ))
            }
          </span>
        </div>
        <div className="d-flex justify-content-center">
          <a
            className="btn btn-link"
            href={order.catalog_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("Ver catálogo")}
          </a>
        </div>
      </div>
    );
  }

  function validateImg(mensagem) {
    // const groupRefer = mensagem.Nfrom && mensagem.Nfrom.split('@')[0];
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.webp)$/i;
    let allowedExtensionsAudio = /(\.mp3|\.ogg)$/i;
    let allowedExtensionsVideo = /(\.mp4)$/i;

    if (allowedExtensions.exec(mensagem.anexo)) {

      // DESATIVAR CDN TEMPORARIAMENTE
      // if (mensagem.anexo.includes?.('empreender.nyc3.cdn'))
      //   mensagem.anexo = mensagem.anexo.replace('.cdn', '');

      const isWebp = mensagem.anexo.endsWith('webp');
      const imageSRC = mensagem.anexo;

      return (
        <>
          {/* {groupRefer !== 'g.us' && mensagem.me === '0' && (
            <h6 className="mb-2">
              {restringirNome(contatoAtivo.nome, 19, true, contatoAtivo.chatid)}
            </h6>
          )} */}
          {/* <div className="d-flex flex-column"> */}
            {/* <h6
              className={
                mensagem.me === '1' && mensagem.privado === '0'
                  ? 'mb-2 text-dark'
                  : ''
              }
            >
              {
                groupRefer !== 'g.us' && (
                  mensagem.me === '1'
                    ? mensagem.userchat !== '0' &&
                      mensagem.userchat !== '-1' &&
                      userchat[mensagem.userchat]
                      ? `${userchat[mensagem.userchat].nome} ${t(
                          'anexou 1 foto'
                        )}`
                      : t('Foto Anexada')
                    : `  ${t('Anexou 1 foto')}`
                  )
              }
            </h6> */}
            <div className="form-row mt-3 position-relative pl-3 pr-3 mb-3">
              <div
                className="message-image"
                style={
                  isWebp
                    ? { height: '150px', width: '150px' }
                    : { height: '250px' }
                }
              >
                <img
                  className="rounded"
                  src={imageSRC}
                  onClick={() => setImageExpand(() => mensagem.anexo)}
                  alt="anexo"
                  width={'100%'}
                  style={{ objectFit: 'cover' }}
                  loading="lazy"
                />
              </div>
              {/* <div className="message-image-shadow rounded-bottom"></div> */}
            </div>
          {/* </div> */}
        </>
      );
    } else if (allowedExtensionsAudio.exec(mensagem.anexo)) {
      return (
        <audio controls>
          <source src={mensagem.anexo} type="audio/mpeg" />
        </audio>
      );
    } else if (allowedExtensionsVideo.exec(mensagem.anexo)) {
      return (
        <video className="tw-rounded-md" width="320" height="240" controls>
          <source src={mensagem.anexo} type="video/mp4" />
        </video>
      );
    } else {
      return (
        <a
          href={mensagem.anexo}
          target="_blank"
          rel="noopener noreferrer"
          className="d-flex align-items-center mr-3"
        >
          <span className="icon-shape">
            <i className="fe-paperclip"></i>
          </span>
          <div className="media-body overflow-hidden flex-fill">
            <span className="ml-4 d-block text-truncate font-medium text-reset">
              {t('Arquivo Externo')}
            </span>
          </div>
        </a>
      );
    }
  }

  function userGroupName(mensagem) {
    const userPhone = formatPhoneNumber(mensagem.Nfrom.split('@')[1]);
    const userName = mensagem.Nfrom.split('~')[1];
    const colorNum =
      mensagem.Nfrom.split('@')[1] &&
      mensagem.Nfrom.split('@')[1].split('~')[0] &&
      mensagem.Nfrom.split('@')[1].split('~')[0].substr(-1, 1);

    const filterContatoExistsNome =
      contatos &&
      Object.values(contatos[lojaAtivo]).filter(
        (item) =>
          item.chatid.split('@')[0] ===
            mensagem.Nfrom.split('@')[1].split('~')[0] && item.id
      )[0];

    const colors = [
      'tomato',
      'darkcyan',
      '#964343',
      'blueviolet',
      'brown',
      'cornflowerblue',
      'crimson',
      'darkorchid',
      'deeppink',
      'mediumseagreen',
    ];

    return (
      <div className="d-flex align-items-center mb-2">
        <small
          className="mr-3 font-weight-bold"
          style={{ color: colors[colorNum], whiteSpace: 'nowrap' }}
        >
          {filterContatoExistsNome && filterContatoExistsNome.nome
            ? filterContatoExistsNome.nome
            : userPhone}
        </small>
        {userName && userName !== 'undefined' && !filterContatoExistsNome ? (
          <small style={{ whiteSpace: 'nowrap' }}>~{userName}</small>
        ) : (
          ''
        )}
      </div>
    );
  }

  function mensagemPrivada(mensagem) {
    return (
      <div className="message mt-3 mb-3" id="msgConv">
        <div className="message-body">
          <div className="message-row">
            <div className="d-flex align-items-center justify-content-center">
              {mensagem.anexo ? (
                <div
                  className="
                    message-image-content tw-rounded-md tw-text-center tw-bg-west-bar dark:tw-bg-west-bar-dark
                    tw-text-gray-500 dark:tw-text-gray-400
                    [&_a]:tw-text-indigo-600 [&_a]:dark:tw-text-indigo-400
                  "
                  // style={{
                  //   background: '#FFFDD9',
                  //   color: 'black',
                  // }}
                >
                  {validateImg(mensagem)}
                  <div
                    className="d-flex flex-column private"
                    style={{
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-word',
                    }}
                  >
                    {filterMensagem(mensagem)}
                  </div>
                  <div className="ml-4">
                    <small
                      className="opacity-65"
                      style={{
                        fontSize: '0.7rem',
                        whiteSpace: 'pre',
                      }}
                    >
                      {formatTimeMensagens(mensagem.data_whats)}
                    </small>
                  </div>
                </div>
              ) : (
                <div
                  className="
                    message-content tw-rounded-xl tw-text-center tw-bg-west-bar dark:tw-bg-west-bar-dark
                    tw-text-gray-500 dark:tw-text-gray-400
                    [&_a]:tw-text-indigo-600 [&_a]:dark:tw-text-indigo-400
                  "
                  // style={{
                  //   background: '#FFFDD9',
                  //   color: 'black',
                  // }}
                >
                  <div
                    className="d-flex flex-column private"
                    style={{
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-word',
                    }}
                  >
                    {filterMensagem(mensagem)}
                  </div>
                  <div>
                    <small
                      className="opacity-65"
                      style={{
                        fontSize: '0.7rem',
                        whiteSpace: 'pre',
                      }}
                    >
                      {formatTimeMensagens(mensagem.data_whats)} por{' '}
                      {autorMensagem(mensagem)}
                    </small>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function mensagemAlerta(mensagem, totalMessages) {
    switch (mensagem.tipo) {
      case 'e2e_notification': // Aviso de conversa criptografada
      case 'notification_template': // Para atualização de conversa temporaria, troca de número etc
        return null;
      default:
        break;
    }

    const existAnexo = mensagem.anexo
      ? Object.values(mensagem.anexo)?.length
      : 0;

    if (!mensagem.texto && !existAnexo) {
      mensagem.texto = `⏱ Aguardando mensagem. Abra o WhatsApp no seu celular.`;
      mensagem.alerta = true;
    }

    const isNotifWpp = infoTypesWhatsapp.includes(mensagem?.tipo);
    if (Number(totalMessages) > 1 && !isNotifWpp) return;

    return (
      <div className="message mt-3 mb-3" id="msgConv">
        <div className="message-body">
          <div className="message-row">
            <div className="d-flex align-items-center justify-content-center tw-text-gray-500 dark:tw-text-gray-400 tw-bg-west-bar dark:tw-bg-west-bar-dark">
              <div
                className="message-content tw-rounded-xl tw-text-center"
                style={{
                  // background: '#b5d7f9',
                  // color: 'black',
                  position: 'relative',
                  minWidth: '15%',
                  borderRadius: '0.400rem',
                  padding: '0.750rem 1.250rem',
                  boxShadow: '1px 1px 1px rgb(0 0 0 / 20%)',
                }}
              >
                {!isNotifWpp && (
                  <Tooltip
                    text={
                      'Última mensagem da conversa, clique em carregar mais para ver todo o conteúdo da mensagem!'
                    }
                  >
                    <i
                      style={{
                        position: 'absolute',
                        top: '-12px',
                        right: '-12px',
                        background: 'white',
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                      }}
                      className="fe fe-clock d-flex align-items-center justify-content-center"
                    ></i>
                  </Tooltip>
                )}
                <div
                  className="d-flex flex-column private"
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                  }}
                >
                  <div
                    key={`${mensagem.inclusao}-${mensagem.id}`}
                    className="d-block"
                    style={{
                      flex: 1,
                      wordBreak: 'break-word',
                      whiteSpace: 'break-spaces',
                    }}
                  >
                    {mensagem.texto}
                  </div>
                </div>
                <div>
                  <small
                    className="opacity-65"
                    style={{
                      fontSize: '0.7rem',
                      whiteSpace: 'pre',
                    }}
                  >
                    {formatTimeMensagens(mensagem.data_whats)}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function mensagensChat(mensagem) {
    if (!mensagem.id) return null;
    const groupRefer =
      mensagem.Nfrom &&
      mensagem.Nfrom.split('@') &&
      mensagem.Nfrom.split('@')[0];

    const revoked = mensagem.tipo === 'revoked';
    const me = mensagem.me === '1';

    return (
      <div
        id="msgConv"
        data-id={mensagem.id}
        className={
          classnames(
            'mt-3 mb-3 message tw-gap-3',
            me && 'message-right',
            revoked && 'tw-opacity-60'
          )
        }
      >
        {
          me && (
            <Tooltip text={autorMensagem(mensagem)}>
              <span className="tw-order-2">
                <Avatar
                  data={
                    mensagem.userchat > '0'
                      ? userchat[mensagem.userchat]
                      : mensagem.userchat
                  }
                />
              </span>
              {/* <span
                className='avatar avatar-sm ml-4 ml-lg-5 d-lg-block'
              >
                <Avatar
                  dados={
                    mensagem.userchat > '0'
                      ? userchat[mensagem.userchat]
                      : mensagem.userchat
                  }
                />
              </span> */}
            </Tooltip>
          )
        }

        {
          me && mensagem.status === '-1' && (
            <Tooltip text="Reenviar mensagem" position="left">
              <span className="ml-4 ml-lg-5 d-block chat-ressend-container">
                <VscSync
                  onClick={() => reenviarMensagem(this, mensagem)}
                  className="chat-ressend"
                />
              </span>
            </Tooltip>
          )
        }

        <div className="message-body">
          <div className="message-row">
            <div
              className={
                classnames(
                  'tw-flex tw-items-center',
                  me ? 'tw-justify-end' : ''
                )
              }
              style={{ position: 'relative' }}
            >
              {infoTypesWhatsapp.includes(mensagem.tipo)
                ? (
                  <div
                    style={{ order: 1 }}
                    className="
                      message-image-content
                      tw-rounded-md tw-relative
                      tw-bg-west-bar dark:tw-bg-west-bar-dark
                      tw-text-gray-500 dark:tw-text-gray-400
                    "
                  >
                    <div className="d-flex position-relative flex-wrap justify-content-end">
                      {filterMensagem(mensagem)}
                    </div>
                  </div>
                ) : (
                  <>
                    {
                      !revoked && me &&
                      (<DropdownMensagem id={mensagem.id} mensagens={mensagens} />)
                    }

                    <div
                      style={{ order: 1 }}
                      className={
                        classnames(
                          'message-image-content tw-rounded-xl tw-relative tw-flex-shrink tw-text-gray-900 dark:tw-text-white [&_a]:tw-text-indigo-600 [&_a]:dark:tw-text-indigo-400',
                          me
                            ? 'tw-bg-message dark:tw-bg-message-dark'
                            : 'tw-bg-west-bar dark:tw-bg-west-bar-dark',
                          mensagem.profano >= 1 && 'message--profane'
                        )
                      }
                    >
                      {
                        groupRefer === 'g.us' &&
                        !me &&
                        mensagem.Nfrom &&
                        userGroupName(mensagem)
                      }

                      {
                        !revoked && (
                          <>
                            {respostaMensagem(mensagem)}
                            {mensagem.anexo && validateImg(mensagem)}
                            {mensagem.tipo === 'vcard' && validateVcard(mensagem)}
                            {mensagem.tipo === 'order' && validateOrder(mensagem)}
                          </>
                        )
                      }

                      <div className="d-flex position-relative flex-wrap justify-content-end">
                        {filterMensagem(mensagem)}
                        <div className="metadata">
                          {mensagem.editado > 0 && (<span className='mr-2'>{t('Editado')}</span>)}
                          {formatTimeMensagens(mensagem.data_whats)}
                          {!revoked && calcStatusMessage(mensagem)}
                        </div>
                      </div>
                    </div>

                    {
                      !revoked && (
                        <VscReply
                          style={
                            me
                              ? { order: 0, marginRight: '10px' }
                              : { order: 2, marginLeft: '10px',}
                          }
                          onClick={() => responderUser(mensagem.id)}
                          className="chat--respon tw-flex-shrink-0"
                        />
                      )
                    }
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <ExpandImage />
      <div className="collapse border-bottom px-lg-8" id="chat-1-search">
        <div className="container-fluid py-4 py-lg-6">
          <div className="input-group">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder={t('Procurar')}
              aria-label={t('Procurar')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="input-group-append">
              <button
                className="btn btn-lg btn-ico btn-secondary btn-minimal"
                type="submit"
              >
                <i className="fe-search" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {contatoAtivo &&
        String(contatoAtivo['naolidas']) !== '0' &&
        String(contatoAtivo['naolidas']) !== '-1' && (
          <div className="position-relative">
            <Container>
              {String(contatoAtivo['naolidas']) !== '1'
                ? String(contatoAtivo['naolidas']) + ' mensagens não lidas.'
                : String(contatoAtivo['naolidas']) + ' mensagem não lida.'}
              <Tooltip text="Clique para marcar todas as mensagens como lida">
                <span onClick={() => marcarLido()}>
                  <i className="fe-check-circle"></i>
                </span>
              </Tooltip>
            </Container>
          </div>
        )}
      <div
        // pl-lg-8 pr-lg-0
        className="chat-content tw-relative tw-pr-1 tw-pl-2"
      >
        <RespostasRapidas />
        <div
          id="container-chat"
          // className="container-fluid py-6 py-lg-6 pl-xl-0"
          className="
            tw-w-full tw-py-3 tw-px-2
            [&::-webkit-scrollbar]:tw-w-1 [&::-webkit-scrollbar-thumb]:!tw-bg-gray-200 [&::-webkit-scrollbar-thumb]:dark:!tw-bg-gray-700
          "
        >
          <RecuperarMensagensAntigas
            desativado={contatoAtivo?.['carregandoConversa']}
          >
            <span onClick={() => carregarMensagens()}>
              {contatoAtivo?.['carregandoConversa']
                ? t('Buscando mensagens, aguarde.')
                : t('Carregar mensagens antigas')}
            </span>
          </RecuperarMensagensAntigas>
          {mensagens &&
            Object.values(mensagens)
              .sort((a, b) => (a.data_whats > b.data_whats ? 1 : -1))
              .slice(
                Object.values(mensagens).length - 250
                  ? Object.values(mensagens).length - 250
                  : 0,
                Object.values(mensagens).length
              )
              .map((mensagem, index, array) => {
                if (typeof mensagem != 'object') return null;
                const totalMessages = array.length;
                mensagem.me = String(mensagem.me);
                mensagem.privado = String(mensagem.privado);
                mensagem.lida = String(mensagem.lida);
                mensagem.lida >= '1'
                  ? (mensagem.lida = '1')
                  : (mensagem.lida = '0');
                mensagem.userchat = String(mensagem.userchat);
                mensagem.userserv = String(mensagem.userserv);
                mensagem.status = String(mensagem.status);

                if (infoTypesWhatsapp.includes(mensagem.tipo))
                  mensagem.privado = 2;

                const tipoMensagem = {
                  0: (msg) => mensagensChat(msg),
                  1: (msg) => mensagemPrivada(msg),
                  2: (msg, total) => mensagemAlerta(msg, total),
                };

                return (
                  <div key={mensagem.id}>
                    <div>{historicoDeMensagens(mensagem, index, array)}</div>
                    {tipoMensagem[mensagem.privado] &&
                      tipoMensagem[mensagem.privado](mensagem, totalMessages)}
                  </div>
                );
              })}
        </div>
      </div>
      {idMessage &&
        idMessage.find((item) => item.conversaID === contatoAtivo.chatid) && (
          <Resposta>
            <div ref={respostaDadosRef}>
              <strong></strong>
              <span></span>
              <i className="fe fe-x" onClick={() => limparResponderUser()}></i>
            </div>
          </Resposta>
        )}
    </>
  );
}

export default memo(Conversa);
