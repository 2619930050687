import React, { useEffect, useState } from 'react';
import { FaUserCircle } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";

import Tooltip from './contents/Tooltip';
import { OldAvatar } from './components/avatar';

import { BackgroundChat, LoadingChat } from './styles/GlobalStyles';

import { ErrorChat } from './components/errors';

import LoadApp from './LoadApp';

import RespostaProvider from './hooks/responderMsgContext';
import RespostasRapidas from './hooks/rapidaMsgContext';

import ListaLojas from './contents/ListaLojas';
import Aside from './contents/Aside';
import CaixaMensagem from './contents/CaixaMensagem';

import { restringirNome } from './utils/formatDados';
import { prefetchQuickReplies } from './utils/prefetch';
import useDadosSakStore from './stores/useDadosSakStore';
import useRenderStore from './stores/useRenderStore';
import CountrySelect from './components/CountrySelect';
import { useTranslation } from 'react-i18next';

function getGreetings () {
  const hours = new Date().getHours();

  if (hours >= 6 && hours < 12) return 'Bom dia';
  else if (hours >= 12 && hours < 18) return 'Boa tarde';
  else return 'Boa noite';
}

export default function Home() {
  const [tab, setTab] = useState('Todos');
  const [search, setSearch] = useState('');

  const contatos = useDadosSakStore((state) => state.contatos);
  const contatoAtivo = useDadosSakStore((state) => state.contatoAtivo);
  const mensagens = useDadosSakStore((state) => state.mensagens);
  const userLogado = useDadosSakStore((state) => state.userLogado);
  const userChat = useDadosSakStore((state) => state.userChat);
  const lojas = useDadosSakStore((state) => state.lojas);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const marcadores = useDadosSakStore((state) => state.marcadores);
  // const globalLoading = useDadosSakStore((state) => state.globalLoading);
  const printWpp = useDadosSakStore((state) => state.printWpp);
  const { t } = useTranslation();

  const lastRender = useRenderStore((state) => state.lastRender);

  function copiarCodigo(texto) {
    if (!texto) return;
    texto = String(texto);
    if (typeof texto !== 'string') return;
    navigator.clipboard
      .writeText(texto)
      .then(() => window.notifChat('Código copiado com sucesso!', 'success'))
      .catch((_) =>
        window.notifChat('Não foi possível copiar o código!', 'warning')
      );
  }

  useEffect(() => {
    if (
      lojas &&
      Object.values(lojas).length &&
      lojaAtivo &&
      Object.values(lojaAtivo).length
    ) {
      window.sakChatToken =
        localStorage.inicializacao &&
        JSON.parse(localStorage.inicializacao).token;
      window.sakChatChave = lojas[lojaAtivo].chave_empreender;

      prefetchQuickReplies();
    }
  }, [lojaAtivo, lojas]);

  useEffect(() => {
    window.sakChatContato = contatoAtivo?.chatid;
    window.sakChatLoja = lojas?.[lojaAtivo]?.user;
    window.sakChatIsMeta = lojas?.[lojaAtivo]?.multidevice < 0;
  }, [lojas, lojaAtivo, contatoAtivo]);

  useEffect(() => {
    // const allChats = document.querySelectorAll('#chat-tab');
    // if (!allChats || !contatoAtivo || (contatoAtivo && !contatoAtivo.chatid))
    //   return;
    // Object.values(allChats).forEach((chat) =>
    //   chat.dataset['ref'].includes(contatoAtivo.chatid)
    //     ? chat.classList.add('border-selected')
    //     : chat.classList.remove('border-selected')
    // );

    if (!contatoAtivo?.chatid) return;

    document.querySelector('.chat-tab.chat-selected')?.classList.remove('chat-selected');
    document.querySelector(`.chat-tab[data-chatid="${contatoAtivo.chatid}"]`)?.classList.add('chat-selected');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, search, contatoAtivo]);

  return (
    <>
      <ErrorChat codeError={8}>
        <LoadApp>
          <div className="layout">
            <div
              className="
                sidebar
                min-[1200px]:tw-max-w-[440px]
                min-[1200px]:tw-min-w-[400px]
                tw-bg-bar dark:tw-bg-bar-dark
                min-[1200px]:tw-border-r tw-border-gray-200 dark:tw-border-gray-700"
            >
              <div className="tw-flex tw-flex-row tw-h-dvh">
                <div className="tw-flex tw-flex-col tw-py-3 tw-gap-3 tw-bg-west-bar dark:tw-bg-west-bar-dark [&>*]:tw-px-3">
                  <a href="# " className="d-block">
                    <img
                      src={`${process.env.PUBLIC_URL}assets/logo.png`}
                      className="mx-auto fill-pink tw-w-10"
                      data-inject-svg=""
                      alt=""
                    />
                  </a>

                  <div
                    className="tw-flex-grow tw-pt-2 -tw-mt-2 hide-scrollbar tab-scroll"
                    // style={{ overflowY: 'overlay !important' }}
                  >
                    <ErrorChat codeError={4}>
                      <ListaLojas />
                    </ErrorChat>
                  </div>

                  <div className="tw-self-center tw-pb-1 tw-flex tw-flex-col-reverse tw-gap-2">
                    <div className="dropdown">
                      <div
                        className="
                          btn btn-sm text-muted w-auto tw-p-1 tw-border-none
                          hover:tw-scale-105 hover:tw-brightness-95 hover:dark:tw-brightness-110
                        "
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <Tooltip text="Perfil" position="right">
                          <FaUserCircle className="tw-w-6 tw-h-6" />
                        </Tooltip>
                      </div>
                      <div className="dropdown-menu mr-5">
                        <a
                          className="dropdown-item d-flex align-items-center border-bottom mb-3"
                          href="# "
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="avatar">
                              <ErrorChat codeError={2}>
                                {userLogado.userchat &&
                                userChat[userLogado.userchat] ? (
                                  <OldAvatar
                                    className="!tw-size-6"
                                    data={userChat[userLogado.userchat]}
                                  />
                                ) : null}
                              </ErrorChat>
                            </div>
                            <div
                              className="ml-4 d-flex flex-column justify-content-center"
                              style={{ lineHeight: '19px' }}
                            >
                              <strong>
                                {userLogado.userchat &&
                                  userChat[userLogado.userchat] &&
                                  restringirNome(
                                    userChat[userLogado.userchat].nome,
                                    15
                                  )}
                              </strong>
                              {userLogado.userchat &&
                              userChat[userLogado.userchat]
                                ? userChat[userLogado.userchat].email
                                : null}
                            </div>
                          </div>
                        </a>
                        <a
                          className="dropdown-item d-flex flex-column"
                          href="# "
                          onClick={() =>
                            copiarCodigo(
                              lojaAtivo !== 0 &&
                                lojas[lojaAtivo] &&
                                lojas[lojaAtivo].chave_empreender
                            )
                          }
                        >
                          <strong style={{ color: 'var(--azul)' }}>
                            {t('Chave Empreender')}
                          </strong>
                          <div>
                            <span className="ml-auto mr-2 fe-copy" />
                            {lojaAtivo !== 0 &&
                              lojas[lojaAtivo] &&
                              lojas[lojaAtivo].chave_empreender}
                          </div>
                        </a>
                        <a
                          className="dropdown-item d-flex align-items-center border-top mt-2"
                          href="https://app.sak.com.br/atualizarUsuario"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('Minha Conta')}{' '}
                          <span className="ml-auto fe-user" />
                        </a>
                        <a
                          id="darkmode"
                          className="dropdown-item d-flex align-items-center border-top mt-2"
                          href="# "
                        >
                          <span className="mr-2">{t('Ativar')} </span>{' '}
                          {t('Modo Noturno')}
                          <span className="ml-3 fe-moon" />
                        </a>
                        <a
                          className="dropdown-item d-flex align-items-center"
                          href="https://app.sak.com.br/dashboard"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('Voltar para o SAK')}{' '}
                          <span className="ml-auto fe-log-out" />
                        </a>
                      </div>
                    </div>

                    <div>
                      <div className="dropdown">
                        <div
                          className="
                            btn btn-sm text-muted w-auto tw-p-1 tw-border-none
                            hover:tw-scale-105 hover:tw-brightness-95 hover:dark:tw-brightness-110
                          "
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <Tooltip text="Configurações" position="right">
                            <FaGear className="tw-w-6 tw-h-6" />
                          </Tooltip>
                        </div>

                        <div className="dropdown-menu">
                          <a
                            className="dropdown-item d-flex align-items-center"
                            href="# "
                            onClick={printWpp}
                          >
                            {t('Ver WhatsappWeb')}
                            <span className="ml-auto pl-3 fe-camera" />
                          </a>
                          <a
                            href="# "
                            id="wppPrint"
                            data-toggle="modal"
                            data-target="#printWhatsapp"
                            style={{ display: 'none' }}
                          >
                            _
                          </a>
                          <a
                            className="dropdown-item d-flex align-items-center"
                            href="# "
                            data-toggle="modal"
                            data-target="#marcadores"
                          >
                            {t('Gerenciar marcadores')}
                            <span className="ml-auto pl-3 fe-flag" />
                          </a>
                          <a
                            className="dropdown-item d-flex align-items-center"
                            href="# "
                            data-toggle="modal"
                            data-target="#notificacoes"
                          >
                            {t('Notificações')}
                            <span className="ml-auto pl-3 fe-bell" />
                          </a>
                          <a
                            className="dropdown-item d-flex align-items-center"
                            href="# "
                            data-toggle="modal"
                            data-target="#otimizacao"
                          >
                            {t('Otimizações')}
                            <span className="ml-auto pl-3 fe-activity" />
                          </a>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="
                d-flex flex-column h-100
                tw-min-w-0 tw-flex-1
                "
                >
                  <div className="container-fluid tw-py-2 tw-px-5">
                    <div className="d-flex justify-content-between align-items-center tw-h-[3.5rem]">
                      <div className="d-flex align-items-center mt-2 text-truncate">
                        <h2 className="text-truncate tw-text-[1.25rem] tw-leading[1.5rem] tw-font-semibold">
                          {lojaAtivo && lojas[lojaAtivo]
                            ? lojas[lojaAtivo].nome
                            : 'Conversas'}
                        </h2>
                      </div>

                      <div
                        className="dropdown"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <CountrySelect />
                      </div>
                    </div>
                  </div>
                  <ErrorChat codeError={3}>
                    {lojaAtivo && (
                      <Aside
                        contatos={contatos[lojaAtivo]}
                        mensagens={mensagens[lojaAtivo]}
                        lojaAtivo={lojaAtivo}
                        marcadores={marcadores[lojaAtivo]}
                        lojas={lojas[lojaAtivo]}
                        search={search}
                        setSearch={setSearch}
                        tab={tab}
                        setTab={setTab}
                        lastRender={lastRender}
                      />
                    )}
                  </ErrorChat>
                </div>
              </div>
            </div>
            <div className="main tw-bg-chat-area dark:tw-bg-chat-area-dark" id="main-chat" data-mobile-height>
              {contatoAtivo && Object.values(contatoAtivo).length ? (
                <RespostaProvider>
                  <RespostasRapidas>
                    <CaixaMensagem
                      // lastMessage={lastMsg ? lastMsg[lastMsg.length - 1] : ''}
                      contato={contatos[lojaAtivo]}
                      userchat={userChat}
                      mensagens={mensagens[lojaAtivo]}
                      inicial={userLogado}
                      lojas={lojas[lojaAtivo]}
                      lojaAtivo={lojaAtivo}
                      marcadores={marcadores[lojaAtivo]}
                    />
                  </RespostasRapidas>
                </RespostaProvider>
              ) : (
                <ErrorChat codeError={7}>
                  {/* {globalLoading['percent'] !== 100 ? (
                    <BackgroundChat>
                      <img
                        className="dark:tw-brightness-90"
                        // src={`${process.env.PUBLIC_URL}assets/backgroundChat.svg`}
                        src={`${process.env.PUBLIC_URL}assets/criativo.webp`}
                        alt="backgroundLoading"
                      />
                      <h5>
                        {t('Carregando')} {globalLoading['step']}
                      </h5>
                      <h6>{t('Este processo pode demorar alguns minutos.')}</h6>
                      <LoadingChat percent={globalLoading['percent']} />
                    </BackgroundChat>
                  ) : ( */}
                  <BackgroundChat
                    display={contatoAtivo && contatoAtivo.chatid}
                  >
                    <img
                      className="dark:tw-brightness-90"
                      // src={`${process.env.PUBLIC_URL}assets/backgroundChat.svg`}
                      src={`${process.env.PUBLIC_URL}assets/criativo.webp`}
                      alt="backgroundChat"
                    />
                    <span>
                      {userLogado.userchat && userChat[userLogado.userchat]
                        ? `${t(getGreetings())}, ` +
                          userChat[userLogado.userchat].nome
                        : `${t('Carregando...')}`}
                    </span>
                    <p className="!tw-text-gray-500 dark:!tw-text-gray-400">
                      {t(
                        'Selecione uma conversa ao lado para começar'
                      )}
                    </p>
                  </BackgroundChat>
                  {/* )} */}
                </ErrorChat>
              )}
            </div>
          </div>
        </LoadApp>
      </ErrorChat>
    </>
  );
}
