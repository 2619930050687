import React from 'react';

import { MdOutlinePhonelinkErase } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

// import { useError, useLojaAtiva, usePrint } from '../../hooks/dadosSak';
import useDadosSakStore from '../../stores/useDadosSakStore';
import { classnames } from '../../utils/usefulFunctions';

function Errors() {
  // const { onError } = useError();
  // const { lojaAtivo } = useLojaAtiva();
  // const { printWpp } = usePrint();

  const onError = useDadosSakStore((state) => state.onError);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const printWpp = useDadosSakStore((state) => state.printWpp);

  return Object.values(onError)
    .filter(
      (erro, index, array) =>
        array.findIndex(
          (err) =>
            err.id === erro.id &&
            err.userserv === erro.userserv &&
            err.tipo === erro.tipo
        ) === index
    )
    .map((erro, index) => {
      if (
        (erro.userserv === String(lojaAtivo) || erro.userserv === '0') &&
        erro.ativo === '1'
      ) {

        return (
          <div
            key={index}
            className={classnames('tw-flex tw-gap-3 tw-rounded-md tw-mx-5 tw-mt-2.5 tw-p-3 tw-items-center dark:tw-bg-west-bar-dark text-sm', {
              'tw-bg-alert-info tw-text-alert-info-text dark:tw-text-alert-info-text-dark': erro.tipo === 'info',
              'tw-bg-alert-warning tw-text-alert-warning-text dark:tw-text-alert-warning-text-dark': erro.tipo === 'warning',
              'tw-bg-alert-danger tw-text-alert-danger-text dark:tw-text-alert-danger-text-dark': erro.tipo === 'danger',
              'tw-bg-alert-success tw-text-alert-success-text dark:tw-text-alert-success-text-dark': erro.tipo === 'success',
            })}
          >
            <div
              className="tw-rounded-full tw-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-size-10"
            >
              {
                erro.tipo === 'info'
                  ? <AiOutlineLoading3Quarters className="tw-size-8 tw-animate-spin" />
                  : <MdOutlinePhonelinkErase className="tw-size-8" />
              }
            </div>
            <div className="tw-flex tw-flex-col">
              <strong>{erro.titulo}</strong>
              {erro.mensagem}
              {erro.id === 'conexao_whatsapp' && (
                <a href="# " onClick={printWpp} className="text-dark">
                  Print do Whatsapp
                </a>
              )}
            </div>
          </div>
        );
      }
      return null;
    });
}

export default Errors;
