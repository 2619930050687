import { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledThing } from './styles';
import { classnames } from '../../utils/usefulFunctions';
import Tooltip from '../Tooltip';

import HomeTab from './tabs/home/HomeTab';
import QuickRepliesTab from './tabs/replies/QuickRepliesTab';
import AudioSpeedTab from './tabs/audioSpeed/AudioSpeedTab';
import FaqTab from './tabs/faq/FaqTab';

function SideExtention() {
  const { t } = useTranslation();

  const [topSelectedTab, setTopSelectedTab] = useState('home');

  return (
    <StyledThing className="h-100 tw-border-l tw-border-gray-200 dark:tw-border-gray-700 tw-bg-bar dark:tw-bg-bar-dark">
      <div className="page-content sak__side_extention_wrapper position-relative">
        <div
          className="side-extension-close-bnt d-flex p-4 position-absolute"
          style={{
            backgroundColor: 'var(--light)',
            transform: 'translateX(-100%)',
            borderRadius: '0 0 0 8px',
            top: 0, left: 1
          }}
        >
          <button
            className="btn px-4 py-3 d-flex align-items-center justify-content-center"
            data-chat-sidebar-toggle="#chat-1-info"
          >
            <i className="fe-chevron-left" style={{ color: 'inherit' }}/>
          </button>
        </div>

        <header className="main-header" role="tablist">
          <Tooltip text={t('Perfil e Pedidos')} position="bottom">
            <button
              className={classnames('main-header-link bg-transparent', { active: topSelectedTab === 'home' })}
              onClick={() => setTopSelectedTab('home')}
              role="tab"
            >
              <i className="fal fa-home-lg"></i>
            </button>
          </Tooltip>
          <Tooltip text={t('Mensagens Rápidas')} position="bottom">
            <button
              className={classnames('main-header-link bg-transparent', { active: topSelectedTab === 'quickMessages' })}
              onClick={() => setTopSelectedTab('quickMessages')}
              role="tab"
            >
              <i className="fal fa-comment-lines"></i>
            </button>
          </Tooltip>
          <Tooltip text={t('Velocidade de mídia')} position="bottom">
            <button
              className={classnames('main-header-link bg-transparent', { active: topSelectedTab === 'audioSpeed' })}
              onClick={() => setTopSelectedTab('audioSpeed')}
              role="tab"
            >
              <i className="fal fa-volume-up"></i>
            </button>
          </Tooltip>
          <Tooltip text={t('FAQ')} position="bottom">
            <button
              className={classnames('main-header-link bg-transparent', { active: topSelectedTab === 'faq' })}
              onClick={() => setTopSelectedTab('faq')}
              role="tab"
            >
              <i className="fal fa-books"></i>
            </button>
          </Tooltip>
        </header>

        {
          topSelectedTab === 'home' && (
            <HomeTab/>
          )
        }

        {
          topSelectedTab === 'quickMessages' && (
            <QuickRepliesTab/>
          )
        }

        {
          topSelectedTab === 'audioSpeed' && (
            <AudioSpeedTab/>
          )
        }

        {
          topSelectedTab === 'faq' && (
            <FaqTab/>
          )
        }
      </div>
    </StyledThing>
  );
}

export default memo(SideExtention);
