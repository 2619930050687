
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function AudioSpeedTab () {
  const { t } = useTranslation();

  const [mediaSpeed, setMediaSpeed] = useState(1);

  useEffect(() => {
    if (window.sakMediaSpeed)
      setMediaSpeed(Math.floor(window.sakMediaSpeed * 100));
  }, []);

  const handleSpeedChange = e => {
    window.sakMediaSpeedHandler(parseFloat((e.target.value / 100).toFixed(2)));
    setMediaSpeed(parseInt(e.target.value));
  }

  return (
    <div style={{ overflow: 'visible' }}>
      <div
        className="main-content-audio"
      >
        <div className="main-text">
          <h4 className="title">{ t('Velocidade de mídia') }</h4>
          <p><span id="speed-value-span">{ mediaSpeed }%</span> { t('Velocidade') }</p>
        </div>

        <div className="slidecontainer">
          <i className="fal fa-volume-up"></i>
          <input
            className="slider [&::-webkit-slider-thumb]:!tw-bg-active"
            type="range"
            min="50"
            max="200"
            step="5"
            value={ mediaSpeed }
            onChange={handleSpeedChange}
          />
        </div>
      </div>
    </div>
  );
}
