import styled from 'styled-components';
import { t } from 'i18next';

import useDadosSakStore from './stores/useDadosSakStore';
import { ReactComponent as EmpreenderLogo } from './assets/empreender.svg';

const MovingSlice = styled.div`
  @keyframes moving {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }

  animation: moving 2s infinite linear;
`;

export default function LoadApp({ children }) {
  const userLogado = useDadosSakStore((state) => state.userLogado);
  const lojas = useDadosSakStore((state) => state.lojas);
  const statusApp = useDadosSakStore((state) => state.statusApp);

  function carregando() {
    if (statusApp === 1) {
      return (
        <div
          id="msgRandom"
          className="
            tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-dvh
            tw-bg-chat-area [@media(prefers-color-scheme:dark)]:tw-bg-chat-area-dark
          "
        >
          <div
            className="
              tw-size-[4.5rem] tw-relative
              tw-bg-gray-700 [@media(prefers-color-scheme:dark)]:tw-bg-gray-400
            "
            style={{
              maskImage: 'url(assets/sak-white.png)',
              maskRepeat: 'no-repeat',
              maskSize: 'contain',
            }}
          >
            <MovingSlice
              className="
                tw-absolute tw-h-full tw-w-full tw-blur-[.5rem]
                tw-bg-gray-400 [@media(prefers-color-scheme:dark)]:tw-bg-gray-300
              "
            />
          </div>

          <span
            className="
              tw-fixed tw-right-8 tw-bottom-8
              tw-text-black [@media(prefers-color-scheme:dark)]:tw-text-white
            "
          >
            <EmpreenderLogo height={24} />
          </span>
        </div>
      );
    } else if (statusApp === 2) {
      return (
        <div
          id="msgRandom"
          className="
            tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-dvh
            tw-bg-chat-area [@media(prefers-color-scheme:dark)]:tw-bg-chat-area-dark
            tw-text-black [@media(prefers-color-scheme:dark)]:tw-text-white
          "
        >
          <div className="reload-app">
            <h2 className='tw-text-inherit'>
              {t(
                'O Chat Sak encontra-se aberto em uma outra aba ou janela. Utilize o botão abaixo para voltar a utilizar nesta aba.'
              )}
            </h2>

            <div className="d-flex align-items-center justify-content-end">
              <button
                className="
                  !tw-bg-west-bar [@media(prefers-color-scheme:dark)]:!tw-bg-west-bar-dark
                  !tw-text-gray-500 [@media(prefers-color-scheme:dark)]:!tw-text-gray-400
                "
                onClick={() =>
                  (window.location.href = 'https://app.sak.com.br/dashboard')
                }
              >
                {t('Sair')}
              </button>
              <button
                className="!tw-bg-indigo-600 !tw-text-white"
                onClick={() => window.location.reload()}
              >
                {t('Utilizar aqui')}
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          id="msgRandom"
          className="
            tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-dvh tw-gap-3
            tw-bg-chat-area [@media(prefers-color-scheme:dark)]:tw-bg-chat-area-dark
            tw-text-gray-700 [@media(prefers-color-scheme:dark)]:tw-text-gray-400
          "
        >
          <div
            className="
              tw-size-[4.5rem] tw-relative
              tw-bg-gray-700 [@media(prefers-color-scheme:dark)]:tw-bg-gray-400
            "
            style={{
              maskImage: 'url(assets/sak-white.png)',
              maskRepeat: 'no-repeat',
              maskSize: 'contain',
            }}
          ></div>

          <span className="tw-text-center tw-flex tw-flex-col">
            <span>{ t('Sua sessão foi expirada') }</span>

            <span>
              <a
                href="https://app.sak.com.br/dashboard"
                className="
                  tw-text-active [@media(prefers-color-scheme:dark)]:tw-text-active-dark
                  hover:tw-underline hover:tw-text-current
                "
              >
                { t('Inicie uma nova') }
              </a> { t('através do dashboard') }
            </span>
          </span>
        </div>
      );
    }
  }

  if (
    lojas &&
    Object.values(lojas).length !== 0 &&
    userLogado &&
    userLogado.sessao !== 'existente'
  ) {
    return children;
  } else {
    return carregando();
  }
}
