import { useState, useEffect, memo } from 'react';

function Avatar({ dados }) {
  const formatConversaID = dados
    ? dados.conversa
      ? String(dados.conversa.split('@')[0]).slice(-2)
      : dados.id
      ? String(dados.id.split('@')[0]).slice(-2)
      : dados.inclusao
      ? String(dados.inclusao).slice(-2)
      : '1'
    : '1';

  const avatarIcon = `${process.env.PUBLIC_URL}assets/avatar/${formatConversaID}.svg`;

  const [imgSrc, setImgSrc] = useState(avatarIcon);

  const onError = () => setImgSrc(avatarIcon);

  useEffect(() => {
    if (dados && dados['photo']) {
      setImgSrc(dados['photo']);
      // eslint-disable-next-line eqeqeq
    } else if (dados == '-1')
      setImgSrc(`${process.env.PUBLIC_URL}assets/robot.svg`);
    else setImgSrc(avatarIcon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dados]);

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      src={imgSrc}
      style={{ width: '35px', height: '35px' }}
      onError={onError}
      loading="lazy"
    />
  );
}

function getAvatar (data, fallback = false) {
  if (data) {
    if (data.photo && !fallback)
      return data.photo;

    // eslint-disable-next-line eqeqeq
    if (data == -1)
      return `${process.env.PUBLIC_URL}assets/robot.svg`;

    const default_id = (data.conversa || data.id || data.inclusao)?.split?.('@')[0].slice(-2);
    if (default_id)
      return `${process.env.PUBLIC_URL}assets/avatar/${default_id}.svg`;
  }

  return `${process.env.PUBLIC_URL}assets/avatar/1.svg`;
}

function NeoAvatar ({ data }) {
  const [imageSrc, setImageSrc] = useState(getAvatar(data));

  const onError = () => setImageSrc(getAvatar(data, true));

  useEffect(() => {
    setImageSrc(getAvatar(data));
  }, [data]);

  return (
    <span className="tw-bg-west-bar dark:tw-bg-west-bar-dark tw-rounded-full">
      <img
        src={imageSrc}
        className="tw-size-11 tw-rounded-full"
        onError={onError}
        loading="lazy"
        alt="Avatar"
      />
    </span>
  )
}

export default memo(NeoAvatar);
export const OldAvatar = memo(Avatar);
