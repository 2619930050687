import styled from 'styled-components';
import { getPalette } from '../../utils/createPalette';

export const CuteTag = styled.span
  .attrs({
    className: `
      tw-inline-flex tw-items-center tw-rounded-md
      tw-bg-[var(--bg-color)] tw-px-2 tw-py-1 tw-text-xs
      tw-font-medium tw-text-[var(--text-color)] tw-ring-1
      tw-ring-inset tw-ring-[var(--ring-color)]
      dark:tw-bg-[var(--bg-color-dark)] dark:tw-text-[var(--text-color-dark)] dark:tw-ring-[var(--ring-color-dark)]
    `
  })
  .attrs(props => {
    if (props.color) {
      const palette = getPalette(props.color, 700);
      const { '700': textColor, '50': bgColor, '600': ringColor, '400': darkColor }
        = Object.fromEntries(palette.map(pair => ([pair.stop, pair.hex])));

      return {
        bgColor,
        textColor,
        ringColor,
        darkColor
      };
    }
  })
`
  --bg-color: ${props => props.bgColor};
  --text-color: ${props => props.textColor};
  --ring-color: ${props => props.ringColor + '80'};
  --bg-color-dark: ${props => props.darkColor + '1A'};
  --text-color-dark: ${props => props.darkColor};
  --ring-color-dark: ${props => props.darkColor + '1A'};
`;

export const Tag = styled.div`
  background: ${(props) => (props.cor ? props.cor : '#49c18f')};
  font-size: 10px;
  color: white;
  padding: 2px 12px;
  margin-right: 3px;
  border-radius: 20px;
`;

export const Badge = styled.div`
  background-color: ${(props) => (props.color ? props.color : '#49c18f')};
  border-radius: 50%;
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: UPPERCASE;

  color: white;
  font-weight: ${(props) => (props.hasuserchat ? 'normal' : 'bold')};
  font-size: 12px;

  margin-right: 5px;
`;

export const BadgeUser = styled.div`
  position: absolute;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  bottom: -2px;
  right: -4px;
  width: 18px;
  height: 18px;
  font-size: 0.5rem;
  background: ${(props) => (props.bg ? props.bg : '#ff5848')};
  border: 1px solid #f5f6fa;
`;
