import { useEffect, useState, useRef } from 'react';
import Tooltip from '../../../Tooltip'
import { HiOutlineMicrophone, HiOutlineStopCircle } from "react-icons/hi2";

import {
  AudioControl,
  StopButton,
  RecordButton,
  AudioPlayerWrapper,
} from './styles';

var recorder;
const RecordAudio = () => {
  // const recorder = useRef(null); //Recorder
  const audioPlayer = useRef(null); //Ref for HTML Audio tag
  const timer = useRef(null); //Ref for interval object
  const time = useRef(0); //Stores the value of time
  const displayTime = useRef(null); //Stores dom ref for div to be used to display time
  const displayTimeOnAudioPlayer = useRef(null);

  const [isRecording, setIsRecording] = useState(null);
  // const [play, setPlay] = useState(false);

  const showAudioPlayer = () => {
    const target = document.querySelector('.audio--player');
    target.style.display = 'block';
    target.setAttribute('data-play-audio-button', true);
  };

  const hideAudioPlayer = () => {
    const target = document.querySelector('.audio--player');
    target.pause();
    target.style.display = 'none';
    target.setAttribute('data-play-audio-button', false);
  };

  const handleRecord = () => {
    let audio = document.querySelector('.audio--player');
    console.log({ audio });
    // Request permissions to record audio
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      hideAudioPlayer();
      recorder = new MediaRecorder(stream);

      // Set record to <audio> when recording will be finished
      recorder.addEventListener('dataavailable', (e) => {
        audio.src = URL.createObjectURL(e.data);

        var audioFile = new File([e.data], 'my_file.weba', {
          type: 'audio/webm',
        });

        window.myDropzoneGlobal.addFile(audioFile);
      });

      // Start recording
      recorder.start();
      setIsRecording(true);
    });
  };

  const handleStop = () => {
    showAudioPlayer();
    // Stop recording
    recorder.stop();
    setIsRecording(false);
    // Remove “recording” icon from browser tab
    recorder.stream.getTracks().forEach((i) => i.stop());
  };

  const startTimer = () => {
    //sets interval that updates time on UI every second
    time.current = 0;
    timer.current = setInterval(() => {
      const secondCounter = time.current % 60;
      const minuteCounter = Math.floor(time.current / 60);
      const computedSecond =
        String(secondCounter).length === 1
          ? `0${secondCounter}`
          : secondCounter;

      const computedMinute =
        String(minuteCounter).length === 1
          ? `0${minuteCounter}`
          : minuteCounter;

      time.current = time.current + 1;
      displayTime.current.innerText = `${computedMinute}: ${computedSecond}`;
      //Displays time by updating the DOM directly
    }, 1000);
  };

  const stopTimer = () => {
    displayTime.current.innerText = '';
    clearInterval(timer.current);
  };

  const setTime = () => {
    if (audioPlayer.current) {
      displayTimeOnAudioPlayer.current.innerText = Math.floor(
        audioPlayer.current.duration
      );
    }
  };

  useEffect(() => {
    isRecording
      ? startTimer()
      : stopTimer();
  }, [isRecording]);

  return (
    <div>
      <AudioControl className='tw-flex tw-gap-3'>
        <Tooltip text="Parar gravação">
          <StopButton
            isRecording={isRecording}
            onClick={handleStop}
          >
            <HiOutlineStopCircle className='tw-h-5 tw-w-5 tw-text-red-500' />
          </StopButton>
        </Tooltip>
        <Tooltip text="Gravar mensagem de voz">
          <RecordButton
            isRecording={isRecording}
            onClick={handleRecord}
          >
            <HiOutlineMicrophone className='tw-h-5 tw-w-5' />
          </RecordButton>
        </Tooltip>
        <span ref={displayTime} style={{ marginRight: '8px' }}>
          {timer.current}
        </span>

        <AudioPlayerWrapper hide={document.documentElement.clientWidth < 680}>
          <audio
            className="audio--player tw-max-h-9"
            style={{ display: 'none' }}
            ref={displayTimeOnAudioPlayer}
            controls="controls"
            onLoadedMetadata={setTime} //fethches metadata info like duration
            onTimeUpdate={setTime} //event handler whenever time progresses on player
          />
        </AudioPlayerWrapper>
      </AudioControl>
    </div>
  );
};
export default RecordAudio;
