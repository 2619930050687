import styled, { keyframes } from 'styled-components';

const animateLeftToRight = keyframes`
  from {
    transform: translateX(-25vw);
  }
  to {
    transform: translateX(0);
  }
`;

export const ContainerContatos = styled.section`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 25vw;
  max-width: 380px;
  min-width: 280px;
  background: var(--light);
  z-index: 9;
  animation: ${animateLeftToRight} 0.1s linear;
  display: flex;
  flex-direction: column;

  > span {
    display: flex;
    align-items: center;
    gap: 15px;
    background: var(--secondary);
    padding: 40px 15px 15px;

    i {
      font-size: 1.4rem;
      color: var(--basic-inverse);
      cursor: pointer;
      padding: 8px;
      border-radius: 50%;
      transition: all 0.3s;

      &:hover {
        background: var(--light-bold);
      }
    }

    h5 {
      margin-bottom: 0;
    }
  }
`;

export const ContainerMessages = styled.div
  .attrs({ className: 'tw-bg-west-bar dark:tw-bg-west-bar-dark tw-rounded-md tw-p-2 tw-mb-2.5' })`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 20px;

  cursor: pointer;
  transition: all 0.3s;

  i {
    margin-left: 11px;
    font-size: 1.2rem;
    color: #49c18f;
  }

  p {
    font-weight: 400;
    margin: 0;
  }

  span {
    background-color: #49c18f;
    border-radius: 50%;
    width: 20px;
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: UPPERCASE;

    color: white;
    font-weight: bold;
    font-size: 12px;

    margin-right: 5px;
    margin-left: auto;
  }
`;
