import {
  // useRef,
  useMemo
} from 'react';
import { CuteTag, Badge } from './styles';

import {
  restringirNome,
  formatTime,
  verData,
  // formatPhoneNumber,
} from '../../utils/formatDados';

import { classnames } from '../../utils/usefulFunctions';

import Avatar from '../../components/avatar';

// import {
//   useContatoAtivo,
//   useMarcadores,
//   useAtendente,
//   useLojaAtiva,
// } from '../../hooks/dadosSak';

import Tooltip from '../Tooltip';

import CacheStore from '../../utils/generateCache';
import useDadosSakStore from '../../stores/useDadosSakStore';
// import useSocketStore from '../../stores/useSocketStore';
import useMarcarLidoStore from '../../stores/useMarcarLidoStore';
// import { getPalette } from '../../utils/createPalette';

export default function Contato({ contato, lastMessage, temMensagemNaoLida }) {
  // const { setContatoAtivo } = useContatoAtivo();
  // const { marcadores } = useMarcadores();
  // const { userChat } = useAtendente();
  // const { lojaAtivo } = useLojaAtiva();
  // const contatoUser = useRef(null);
  // const sendSocketDados = useSocketStore((state) => state.sendSocketDados);
  const setContatoAtivo = useDadosSakStore(
    (state) => state.setContatoAtivoStore
  );
  const userChat = useDadosSakStore((state) => state.userChat);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const marcadores = useDadosSakStore((state) => state.marcadores);
  const setShowMarcarLidoBtn = useMarcarLidoStore(
    (state) => state.setShowMarcarLidoBtn
  );
  const toggleMarcarLidoBoxAndCheckbox = useMarcarLidoStore(
    (state) => state.toggleMarcarLidoBoxAndCheckbox
  );
  const setCheckboxMarcadosComoLidoQuantity = useMarcarLidoStore(
    (state) => state.setCheckboxMarcadosComoLidoQuantity
  );

  const playWhatsappAudio = () => {
    const isCheckboxReceiveMessageActive =
      JSON.parse(localStorage.getItem('checkboxOnReceiveMessageSound')) ||
      false;

    if (isCheckboxReceiveMessageActive) {
      if (
        (Number(contato.naolidas) > 0 || temMensagemNaoLida) &&
        !document.hasFocus()
      ) {
        const audio = new Audio('assets/WhatsAppTune.mp3');
        audio.play();
      }
    }
  };

  const handlePlayAudioCache = () => {
    let cacheStore = new CacheStore();
    cacheStore.remember('notification-sound-1', 12, () => {
      playWhatsappAudio();
      return 1;
    });
  };

  const flagMessageIsMe = useMemo(() => {
    handlePlayAudioCache();

    if (
      !lastMessage ||
      typeof lastMessage != 'object' ||
      !Number(lastMessage.me)
      // !Number(lastMessage?.['me'])
    ) return;

    /*
      statusAtual só é utilizado se lastMessage.me !== '1'
        se lastMessage.me não for '1', então será '0' e será filtrado pelo if acima
    */

    // let statusAtual = '3';

    // const contatoNaoLidas = contato['naolidas']
    //   ? String(contato['naolidas'])
    //   : 0;

    // const naoLida = Object.values(lastMessage)
    //   .sort((a, b) => (a.data_whats > b.data_whats ? 1 : -1))
    //   .slice(
    //     Number.isInteger(Number(contatoNaoLidas)) ? -Number(contatoNaoLidas) : 0
    //   )
    //   .map((item) => item.texto);
    // if (naoLida.includes(lastMessage.texto)) statusAtual = '2';
    // if (contatoNaoLidas === '0' || contatoNaoLidas === '-1') statusAtual = '3';

    return (
      <img
        className="mr-2"
        width={15}
        src={
          process.env.PUBLIC_URL +
          'assets/msgStatus' +
          lastMessage.status +
          // (lastMessage.me === '1' ? lastMessage.status : statusAtual) +
          '.svg'
        }
        loading="lazy"
        alt={`status${lastMessage.status}`}
      />
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage, contato]);

  const listaMarcadores = useMemo(() => {
    const allMarcadores = marcadores && marcadores[lojaAtivo];

    return (
      allMarcadores &&
      contato.marcadores &&
      contato.marcadores.map(
        (id, _index) => {
          if (!id || !allMarcadores[id]) return void 0;

          return (
            <CuteTag
              key={'marcador_' + id}
              color={allMarcadores[id].cor || '49c18f'}
            >
              { allMarcadores?.[id]?.titulo || '' }
            </CuteTag>
          );
        }
      ).filter(Boolean)
    );
  }, [marcadores, lojaAtivo, contato.marcadores]);

  function TrocarChat() {
    const clearChatMessage = () => {
      const textAreaInput = document.getElementById('chat-id-1-input');
      if (textAreaInput) {
        textAreaInput.value = '';
      }
    };
    clearChatMessage();

    const chat = document.getElementById('main-chat');
    // const convs = document.querySelectorAll('#chat-tab');

    const findExtensao = document.querySelector('#chat-1-info');

    // const findExtensaoSearch = document.getElementById('sakBusca');
    // const findExtensaoContent = document.querySelector('#sak .tab-content');
    // const convId = contatoUser.current;

    // const ref = convId?.dataset?.['ref'];

    // if (findExtensaoSearch && ref) {
    //   let numeroContato = '+' + ref?.split('@')?.[0]?.toString();
    //   if (numeroContato) {
    //     numeroContato = formatPhoneNumber(numeroContato);
    //     findExtensaoSearch.value = numeroContato;
    //   }
    // }

    // if (findExtensaoContent) {
    //   findExtensaoContent.innerHTML = `
    //     <div class="sak--load">
    //       <span></span>
    //       <h4>Carregando, aguarde!</h4>
    //     </div>
    //   `;
    // }

    // convs &&
    //   Array.from(convs).some((item) =>
    //     item.classList.remove('border-selected')
    //   );
    // document.querySelector('.chat-tab.border-selected')?.classList.remove('border-selected');

    // convId && convId.classList.add('border-selected');


    chat && chat.classList.add('main-visible');
    findExtensao &&
      window.innerWidth < 1200 &&
      findExtensao.classList.contains('chat-sidebar-visible') &&
      findExtensao.classList.remove('chat-sidebar-visible');

    setContatoAtivo(() => contato);
  }

  return (
    <div className="tw-flex">
      {toggleMarcarLidoBoxAndCheckbox ? (
        <input
          type="checkbox"
          name="checkbox_marcar_lido"
          style={{ margin: '0 8px' }}
          onChange={() => {
            const values = document.querySelectorAll('[name="checkbox_marcar_lido"]:checked')?.length;
            setCheckboxMarcadosComoLidoQuantity(values);
            const atLeastOneChecked = !!document.querySelector('[name="checkbox_marcar_lido"]:checked');
            setShowMarcarLidoBtn(atLeastOneChecked);
          }}
          value={contato['chatid'] || contato['conversa_id'] || ''}
        ></input>
      ) : null}

      <span
        className="
          sak--contato !tw-border-none
          chat-tab tw-p-0 tw-w-full tw-rounded-md tw-cursor-pointer
          hover:tw-bg-west-bar dark:hover:tw-bg-west-bar-dark
          [&.chat-selected]:!tw-bg-selected-chat [&.chat-selected]:dark:!tw-bg-selected-chat-dark
        "
        onClick={() => TrocarChat()}
        role="listitem"
        aria-controls="chat"
        // role="tab"
        // aria-selected="false"
        data-chatid={contato['chatid'] || contato['conversa_id'] || ''}
        // ref={contatoUser}
      >
        <div
          className="card bg-transparent rounded-0 border-0"
          style={{ position: 'relative' }}
        >
          <div className="card-body tw-p-3">
          {/* <div className="card-body tw-py-0 tw-px-3 mt-3 mb-3"> */}
            <div
              className="d-flex align-items-center tw-gap-3"
              style={
                String(contato?.arquivado) !== '0' ? { opacity: '0.45' } : {}
              }
            >
              <Avatar data={contato} />
              <div className="tw-flex-1 tw-overflow-hidden">
                <div className="tw-flex tw-flex-col">
                  <div className="d-flex tw-items-start tw-justify-center tw-gap-3">
                    <div
                      className="sak--contato-infos d-flex flex-column mr-auto"
                      style={{ maxWidth: '200px', wordBreak: 'break-word' }}
                    >
                      <h6 className="text-truncate mr-3 mb-0 tw-text-sm tw-leading-6 tw-font-medium">
                        {restringirNome(contato.nome, null, null, contato.chatid)}
                      </h6>

                      <span
                        className="tw-flex tw-items-center tw-text-sm tw-leading-6 tw-font-normal"
                        title={contato?.['ultimamsgtexto'] || ''}
                      >
                        {flagMessageIsMe}
                        {contato['ultimamsgtexto'] === ' '
                          ? '<i className="fe fe-link mr-2"></i> Anexo'
                          : contato['ultimamsgtexto']?.replace('/n', ' ')}
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      {contato.chatid && contato['ultimamsg'] && (
                        <span
                          className={
                            classnames(
                              'tw-p-0.5 tw-text-nowrap tw-text-right tw-text-xs tw-leading-5 tw-font-medium',
                              { 'tw-text-active dark:tw-text-active-dark': contato.naolidas > 0 }
                            )
                          }
                          id={'mensagem_' + contato.chatid}
                          onMouseEnter={() =>
                            verData(contato.chatid, contato['ultimamsg'], 1)
                          }
                          onMouseOut={() =>
                            verData(contato.chatid, contato['ultimamsg'], 0)
                          }
                        >
                          {formatTime(contato['ultimamsg'])}
                        </span>
                      )}

                      <div className="d-inline-flex tw-items-center tw-content-start tw-flex-row-reverse tw-gap-1.5 tw-h-5">
                        {contato.naolidas &&
                          contato.naolidas !== '0' &&
                          contato.naolidas !== '-1' && (
                            <span className="tw-text-active dark:tw-text-active-dark tw-text-xs tw-leading-5 tw-px-2 tw-font-medium dark:tw-bg-indigo-100/10 dark:tw-rounded-full">
                              {contato.naolidas > 99 ? '+99' : contato.naolidas}
                            </span>
                          )}
                        {userChat && userChat?.[contato?.userchat] && (
                          <Tooltip
                            text={userChat[contato.userchat]?.nome || 'SAK'}
                          >
                            <Badge
                              className="!tw-m-0"
                              data-for="main"
                              data-tip={userChat?.[contato.userchat]?.nome || 'SAK'}
                              hasuserChat
                              color={userChat?.[contato?.userchat]?.cor || '#ccc'}
                            >
                              {restringirNome(userChat?.[contato?.userchat]?.nome || 'SAK', 1)}
                            </Badge>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    // tw-flex-wrap
                    className="
                      tw-flex tw-flex-nowrap
                      tw-items-center tw-justify-start tw-gap-1
                      [&>*]:tw-flex-shrink-0 hide-scrollbar tw-overflow-x-auto
                    "
                  >
                    {listaMarcadores}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </div>
  );
}
