import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function scrapeChatData () {
  const contatoNome = document.querySelector('#contatoNome');
  const contatoWhatsapp = document.querySelector('#contatoWhatsapp');

  return {
    nome: contatoNome?.innerText,
    whatsapp: contatoWhatsapp?.value
  }
}

export default function ProfileTab ({
  client_info,
  saveClientInfo,
  setClientData,
  leadLists,
  warningMessage
}) {
  const { t } = useTranslation();
  const [clientIndex, setClientIndex] = useState(0);

  function updateClientData (index, key, value) {
    const newClientData = [...client_info];

    if (!newClientData[index])
      newClientData[index] = {};

    if (typeof key === 'object')
      newClientData[index] = { ...newClientData[index], ...key };
    else
      newClientData[index][key] = value;

    setClientData(newClientData);
  }

  useEffect(() => {
    if (clientIndex > 0 && clientIndex > client_info.length - 1) {
      setClientIndex(0);
      return;
    }

    if (!client_info[clientIndex]) {
      const { nome, whatsapp } = scrapeChatData();

      updateClientData(clientIndex, {
        nome,
        whatsapp,
        phone: whatsapp
      });
    }
  // eslint-disable-next-line
  }, [client_info, clientIndex]);

  const client = client_info?.[clientIndex] || {};

  return (
    <>
      {
        client_info.length > 1 && (
          <div className="main-nav-items">
            <ul
              className="nav nav-tabs SFnav"
              role="tablist"
            >
              {
                client_info.map((client, index) => (
                  <li className="nav-item" key={'client_info_' + index}>
                    <button
                      className={'nav-link' + (index === clientIndex ? ' active' : '')}
                      onClick={() => setClientIndex(index)}
                      role="tab"
                      aria-controls={ client.nome || '?' }
                      aria-selected={ clientIndex === index }
                    >
                      { client.nome || '?' }
                    </button>
                  </li>
                ))
              }
            </ul>
          </div>
        )
      }

      <div className="tab-content p-0" id="pills-tabContent">
        <div>
          <div className="sak--form-group box-perfil">
            <div>
              <i className="fe fe-user"/>
              <input
                type="text"
                placeholder={t("Nome do cliente")}
                value={client.nome ?? ''}
                onChange={e => updateClientData(clientIndex, 'nome', e.target.value)}
              />
            </div>

            <div>
              <i className="fe fe-at-sign"/>
              <input
                type="email"
                placeholder={t("E-mail")}
                value={client.email ?? ''}
                onChange={e => updateClientData(clientIndex, 'email', e.target.value)}
              />
            </div>

            <div>
              <i className="fe fe-phone-call"/>
              <input
                type="text"
                placeholder={t("Telefone")}
                value={client.phone ?? ''}
                onChange={e => updateClientData(clientIndex, 'phone', e.target.value)}
              />
            </div>

            <div>
              <i className="fab fa-whatsapp" style={{ marginTop: '2px' }}/>
              <input
                type="text"
                placeholder="WhatsApp"
                value={client.whatsapp ?? ''}
                id="user-profile-whatsapp"
                onChange={e => updateClientData(clientIndex, 'whatsapp', e.target.value)}
              />
            </div>

            <label htmlFor="">{ t('Anotações') }</label>
            <textarea rows="3" name="perfil-anotacoes" value={client.anotacoes || ''} onChange={e => updateClientData(clientIndex, 'anotacoes', e.target.value)} />

            <div>
              <input
                type="checkbox"
                style={{ width: '30px', flex: 'none' }}
                checked={client.isLead > 0}
                onChange={e => updateClientData(clientIndex, 'isLead', e.target.checked)}
              /> { t('Transformar em lead?') }
            </div>

            {
              client.isLead > 0 && (
                <section className='page-content-scroll' style={{ maxHeight: '320px', marginBottom: '1rem' }}>
                  {
                    leadLists.map(leadList => (
                      <div key={'leadlist_' + leadList.id}>
                        <input
                          type="radio"
                          style={{ width: '30px', flex: 'none' }}
                          value={leadList.id}
                          checked={leadList.id === client.listaId}
                          onChange={e => updateClientData(clientIndex, 'listaId', leadList.id)}
                        />
                        {leadList.nome}
                      </div>
                    ))
                  }
                </section>
              )
            }

            <button className="!tw-bg-active !tw-text-white" onClick={() => saveClientInfo(clientIndex)}>
              {
                client.id_cliente
                  ? t('Salvar alterações')
                  : t('Adicionar cliente')
              }
            </button>
          </div>

          <div>
            {warningMessage}
          </div>
        </div>
      </div>
    </>
  );
}
