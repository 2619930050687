import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsPtBR from './pt-BR.json';
import translationsEs from './es.json';

i18n
  .use(initReactI18next)

  .init({
    resources: {
      ['pt-BR']: { translation: translationsPtBR },
      es: { translation: translationsEs },
      ['es-mx']: { translation: translationsEs },
      ['es-ar']: { translation: translationsEs },
      ['es-cl']: { translation: translationsEs },
      ['es-co']: { translation: translationsEs },
    },
    // fallbackLng: JSON.parse(localStorage.getItem("user"))?.language ?? (initial_language?.code ?? (document.documentElement.lang == 'es' ? 'es-mx' : 'pt-BR')),
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    fallbackLng: "pt-BR",
  });

export default i18n;
